import mmenu from "./menu";
//import gallery from "./gallery";
//import mainmenu from "./main-menu";
import { domReady } from "./helpers";

// import FilterList from './filter';

// // Claess
// import Accordion from "./accordians";
// //import ScrollHeader from "./scroll-header";
// import Header from "./header";
// import Gallery from "./gallery";
// import observer from './scroll';
//import list from "./list";

class App {
    
    static start() {
        return new App()
    }

    constructor() {
        Promise
            .all([
                domReady()
            ])
            .then(
                this.init.bind(this)
            )
    }

    
 
    init() {
        console.info('🚀App:i3nit');

        mmenu();
        
    }

}

App.start()